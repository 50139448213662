import { render, staticRenderFns } from "./Recipe.vue?vue&type=template&id=1592602a&scoped=true&"
import script from "./Recipe.vue?vue&type=script&lang=js&"
export * from "./Recipe.vue?vue&type=script&lang=js&"
import style0 from "./Recipe.vue?vue&type=style&index=0&id=1592602a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1592602a",
  null
  
)

export default component.exports