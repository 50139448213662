<template>
  <div style="text-align: left; min-width: 410px">
    <div
      class="my-12 pb-12"
      style="
        width: 85%;
        max-width: 1100px;
        margin: auto;
        font-family: 'Noto Sans JP', sans-serif;
        line-height: 45px;
        font-style: normal;
        font-size: 20px;
      "
    >
      <div v-if="$vuetify.breakpoint.mdAndUp" class="mb-4">
        <div style="text-align: center; width: 100%; margin-top: -15px">
          <span style="line-height: 212px; font-size: 56px; font-weight: 900">普段使いのアレンジレシピ</span>
        </div>
        <div
          v-if="$vuetify.breakpoint.lgAndUp"
          style="text-align: center; position: absolute; top: 130px; left: 0; right: 0; width: 100%; margin: auto"
        >
          <span style="font-family: Helvetica; font-size: 120px; font-weight: bold; opacity: 0.08"
            >Delicious Preparation</span
          >
        </div>
        <div
          v-if="$vuetify.breakpoint.mdOnly"
          style="
            text-align: center;
            position: absolute;
            top: 130px;
            left: 0;
            right: 0;
            width: 100%;
            height: 127px;
            margin: auto;
          "
        >
          <span style="font-family: Helvetica; font-size: 80px; font-weight: bold; opacity: 0.08"
            >Delicious Preparation</span
          >
        </div>
      </div>
      <div v-if="$vuetify.breakpoint.smOnly" class="mb-1">
        <div style="text-align: center; width: 100%; min-width: 370px; margin-top: -33px">
          <span style="line-height: 250px; min-width: 370px; font-size: 35px; font-weight: 900"
            >普段使いのアレンジレシピ</span
          >
        </div>
        <div
          style="
            text-align: center;
            position: absolute;
            top: 120px;
            left: 0;
            right: 0;
            width: 100%;
            height: 300px;
            margin: auto;
          "
        >
          <span style="font-family: Helvetica; line-height: 60px; font-size: 58px; font-weight: bold; opacity: 0.08"
            >Delicious Preparation</span
          >
        </div>
      </div>
      <div v-if="!$vuetify.breakpoint.smAndUp" class="mb-1">
        <div style="text-align: center; width: 100%; margin-top: -50px">
          <span style="line-height: 250px; font-size: 26px; font-weight: 900">普段使いのアレンジレシピ</span>
        </div>
        <div
          style="
            text-align: center;
            position: absolute;
            top: 75px;
            left: 0;
            right: 0;
            width: 100%;
            height: 300px;
            margin: auto;
          "
        >
          <span style="font-family: Helvetica; line-height: 40px; font-size: 56px; font-weight: bold; opacity: 0.08"
            >Delicious Preparation</span
          >
        </div>
      </div>
      <div id="menu1" style="margin-top: -60px">
        <div class="mb-3" style="text-align: center; font-size: 30px; font-weight: bold">
          <span>らふてー三つ葉うどん</span>
        </div>
        <v-img src="../assets/images0116/pc/menu/recipe1.png" class="img my-6"></v-img>
        <div style="max-width: 754px; margin: auto; font-size: 18px">
          <span>
            ■材料1人分
            <br />
            らふてー丼…１袋（200g）
            <br />
            うどん（チルド、冷凍など）…1玉（180g）
            <br />
            半熟卵…1個 三つ葉…3本（約10g）
            <br />
            紅ショウガ…適量
            <br />
            ■作り方
            <br />
            ①
            らふてー丼とうどんを表示通り温める。その間に洗って水気を切った三つ葉を食べやすい大きさ（約3㎝）に切る。半熟卵は殻をむいて半分に切る。
            <br />
            ② 器に①のうどんを入れ、その上に汁ごとらふてー丼、三つ葉、半熟卵、紅ショウガをのせる。
            <br />
            ※三つ葉の代わりにパクチーにするとエスニック風になります
          </span>
        </div>
        <div style="max-width: 754px; margin: auto; border: 1px solid #354e57; font-size: 18px" class="mt-8 px-10 py-4">
          <span>
            らふてー三つ葉うどんの栄養価
            <br />
            エネルギー759kcal / たんぱく質41.0g / 脂質39.4g / 炭水化物59.9g / 食塩相当量3.3g
          </span>
        </div>
      </div>
      <div id="menu2" class="my-12 py-12">
        <div class="mb-3" style="text-align: center; font-size: 30px; font-weight: bold">
          <span>ガリバタキャベツスープカレー</span>
        </div>
        <v-img src="../assets/images0116/pc/menu/recipe2.png" class="img my-6"></v-img>
        <div style="max-width: 754px; margin: auto; font-size: 18px">
          <span>
            ■材料1人分
            <br />
            富良野スープカレー・チキン…1袋（300g）
            <br />
            キャベツ…1枚（約70g）
            <br />
            ニラ…4～5本（約30g）
            <br />
            刻みニンニク（ビン詰め、生でも可）…小さじ1/2（約3g）
            <br />
            バター…5g
            <br />
            お好みで糸唐辛子…少々
            <br />
            ■作り方
            <br />
            ① スープカレーを表示通り温める。（湯煎の場合は加熱後器に盛りつける）
            <br />
            ②
            耐熱容器に、洗って水気を切り食べやすい大きさに切ったキャベツ、ニラを入れ、上に刻みニンニクとバターをのせ、ラップをして電子レンジ（500W）で4分加熱する。加熱後全体を混ぜ合わせ、①のスープカレーの中に入れて、お好みで糸唐辛子をのせる。
            <br />
            ※キャベツ、ニラだけでもOKですが、バターと刻みニンニクを入れるとコク深い味わいに。
            <br />
            ※れんこん、なす、かぼちゃ、じゃが芋、人参などの素揚げやグリル野菜をトッピングしても美味しいです。ゆで卵やウインナー、チーズをトッピングするのもおススメです。
            <br />
            糸唐辛子は彩のためにいれました。なくても大丈夫です。又は他のものでもOKです。
          </span>
        </div>
        <div style="max-width: 754px; margin: auto; border: 1px solid #354e57; font-size: 18px" class="mt-8 px-10 py-4">
          <span>
            ガリバタキャベツスープカレーの栄養価
            <br />
            エネルギー 470kcal / たんぱく質 32.5g / 脂質 27.7g / 炭水化物 21.6g / 食塩相当量 3.4g
          </span>
        </div>
      </div>
      <div id="menu3">
        <div class="mb-3" style="text-align: center; font-size: 30px; font-weight: bold">
          <span>チンして混ぜるだけステーキペッパーライス</span>
        </div>
        <v-img src="../assets/images0116/pc/menu/recipe3.png" class="img my-6"></v-img>
        <div style="max-width: 754px; margin: auto; font-size: 18px">
          <span>
            ■材料1人分
            <br />
            北海道牛肉ステーキ…1袋（140g）
            <br />
            パックご飯…１つ（200g）
            <br />
            コーン（缶、パウチ、冷凍など）…大さじ２強（約30g）
            <br />
            カット青ねぎ…大さじ2強（約10g）
            <br />
            ■作り方
            <br />
            ① パックご飯と牛肉ステーキを表示通り温める。
            <br />
            ② ①を器に入れ、コーンとカット青ねぎも入れて混ぜ合わせ、黒こしょうをかける。
            <br />
            ※味付けは牛肉ステーキのソースのみでOK。お好みで醤油や塩を加えてもOKですが、なるべく塩分量は控えたいところ。黒こしょうをたっぷりかけて召し上がってください。途中で酢やラー油をかけて味変するのもおススメです。
            <br />
            ※さらにアレンジ♪
            <br />
            混ぜ合わせるだけでも美味しいですが、フライパンで炒めるとチャーハンに！バター、ニンニクと一緒に、玉ねぎやピーマンなどお好みの野菜と炒めるのもおススメです。
          </span>
        </div>
        <div style="max-width: 754px; margin: auto; border: 1px solid #354e57; font-size: 18px" class="mt-8 px-10 py-4">
          <span>
            チンして混ぜるだけステーキペッパーライスの栄養価
            <br />
            エネルギー 566kcal / たんぱく質 27.6g / 脂質 10.9g / 炭水化物 86.0g / 食塩相当量 2.3g
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  mounted() {
    this.toPoint()
  },
  methods: {
    toPoint() {
      const key = this.$route.query.Anchor
      if (key === '1') {
        const bookMark = document.getElementById('menu1')
        bookMark.scrollIntoView()
      }
      if (key === '2') {
        const bookMark = document.getElementById('menu2')
        bookMark.scrollIntoView()
      }
      if (key === '3') {
        const bookMark = document.getElementById('menu3')
        bookMark.scrollIntoView()
      }
    },
  },
}
</script>

<style scoped>
.img {
  width: 754px;
  height: 545px;
  margin: auto;
}
</style>
