var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"left","min-width":"410px"}},[_c('div',{staticClass:"my-12 pb-12",staticStyle:{"width":"85%","max-width":"1100px","margin":"auto","font-family":"'Noto Sans JP', sans-serif","line-height":"45px","font-style":"normal","font-size":"20px"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"mb-4"},[_vm._m(0),(_vm.$vuetify.breakpoint.lgAndUp)?_c('div',{staticStyle:{"text-align":"center","position":"absolute","top":"130px","left":"0","right":"0","width":"100%","margin":"auto"}},[_c('span',{staticStyle:{"font-family":"Helvetica","font-size":"120px","font-weight":"bold","opacity":"0.08"}},[_vm._v("Delicious Preparation")])]):_vm._e(),(_vm.$vuetify.breakpoint.mdOnly)?_c('div',{staticStyle:{"text-align":"center","position":"absolute","top":"130px","left":"0","right":"0","width":"100%","height":"127px","margin":"auto"}},[_c('span',{staticStyle:{"font-family":"Helvetica","font-size":"80px","font-weight":"bold","opacity":"0.08"}},[_vm._v("Delicious Preparation")])]):_vm._e()]):_vm._e(),(_vm.$vuetify.breakpoint.smOnly)?_c('div',{staticClass:"mb-1"},[_vm._m(1),_vm._m(2)]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"mb-1"},[_vm._m(3),_vm._m(4)]):_vm._e(),_c('div',{staticStyle:{"margin-top":"-60px"},attrs:{"id":"menu1"}},[_vm._m(5),_c('v-img',{staticClass:"img my-6",attrs:{"src":require("../assets/images0116/pc/menu/recipe1.png")}}),_vm._m(6),_vm._m(7)],1),_c('div',{staticClass:"my-12 py-12",attrs:{"id":"menu2"}},[_vm._m(8),_c('v-img',{staticClass:"img my-6",attrs:{"src":require("../assets/images0116/pc/menu/recipe2.png")}}),_vm._m(9),_vm._m(10)],1),_c('div',{attrs:{"id":"menu3"}},[_vm._m(11),_c('v-img',{staticClass:"img my-6",attrs:{"src":require("../assets/images0116/pc/menu/recipe3.png")}}),_vm._m(12),_vm._m(13)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","width":"100%","margin-top":"-15px"}},[_c('span',{staticStyle:{"line-height":"212px","font-size":"56px","font-weight":"900"}},[_vm._v("普段使いのアレンジレシピ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","width":"100%","min-width":"370px","margin-top":"-33px"}},[_c('span',{staticStyle:{"line-height":"250px","min-width":"370px","font-size":"35px","font-weight":"900"}},[_vm._v("普段使いのアレンジレシピ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","position":"absolute","top":"120px","left":"0","right":"0","width":"100%","height":"300px","margin":"auto"}},[_c('span',{staticStyle:{"font-family":"Helvetica","line-height":"60px","font-size":"58px","font-weight":"bold","opacity":"0.08"}},[_vm._v("Delicious Preparation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","width":"100%","margin-top":"-50px"}},[_c('span',{staticStyle:{"line-height":"250px","font-size":"26px","font-weight":"900"}},[_vm._v("普段使いのアレンジレシピ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","position":"absolute","top":"75px","left":"0","right":"0","width":"100%","height":"300px","margin":"auto"}},[_c('span',{staticStyle:{"font-family":"Helvetica","line-height":"40px","font-size":"56px","font-weight":"bold","opacity":"0.08"}},[_vm._v("Delicious Preparation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3",staticStyle:{"text-align":"center","font-size":"30px","font-weight":"bold"}},[_c('span',[_vm._v("らふてー三つ葉うどん")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"max-width":"754px","margin":"auto","font-size":"18px"}},[_c('span',[_vm._v(" ■材料1人分 "),_c('br'),_vm._v(" らふてー丼…１袋（200g） "),_c('br'),_vm._v(" うどん（チルド、冷凍など）…1玉（180g） "),_c('br'),_vm._v(" 半熟卵…1個 三つ葉…3本（約10g） "),_c('br'),_vm._v(" 紅ショウガ…適量 "),_c('br'),_vm._v(" ■作り方 "),_c('br'),_vm._v(" ① らふてー丼とうどんを表示通り温める。その間に洗って水気を切った三つ葉を食べやすい大きさ（約3㎝）に切る。半熟卵は殻をむいて半分に切る。 "),_c('br'),_vm._v(" ② 器に①のうどんを入れ、その上に汁ごとらふてー丼、三つ葉、半熟卵、紅ショウガをのせる。 "),_c('br'),_vm._v(" ※三つ葉の代わりにパクチーにするとエスニック風になります ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-8 px-10 py-4",staticStyle:{"max-width":"754px","margin":"auto","border":"1px solid #354e57","font-size":"18px"}},[_c('span',[_vm._v(" らふてー三つ葉うどんの栄養価 "),_c('br'),_vm._v(" エネルギー759kcal / たんぱく質41.0g / 脂質39.4g / 炭水化物59.9g / 食塩相当量3.3g ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3",staticStyle:{"text-align":"center","font-size":"30px","font-weight":"bold"}},[_c('span',[_vm._v("ガリバタキャベツスープカレー")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"max-width":"754px","margin":"auto","font-size":"18px"}},[_c('span',[_vm._v(" ■材料1人分 "),_c('br'),_vm._v(" 富良野スープカレー・チキン…1袋（300g） "),_c('br'),_vm._v(" キャベツ…1枚（約70g） "),_c('br'),_vm._v(" ニラ…4～5本（約30g） "),_c('br'),_vm._v(" 刻みニンニク（ビン詰め、生でも可）…小さじ1/2（約3g） "),_c('br'),_vm._v(" バター…5g "),_c('br'),_vm._v(" お好みで糸唐辛子…少々 "),_c('br'),_vm._v(" ■作り方 "),_c('br'),_vm._v(" ① スープカレーを表示通り温める。（湯煎の場合は加熱後器に盛りつける） "),_c('br'),_vm._v(" ② 耐熱容器に、洗って水気を切り食べやすい大きさに切ったキャベツ、ニラを入れ、上に刻みニンニクとバターをのせ、ラップをして電子レンジ（500W）で4分加熱する。加熱後全体を混ぜ合わせ、①のスープカレーの中に入れて、お好みで糸唐辛子をのせる。 "),_c('br'),_vm._v(" ※キャベツ、ニラだけでもOKですが、バターと刻みニンニクを入れるとコク深い味わいに。 "),_c('br'),_vm._v(" ※れんこん、なす、かぼちゃ、じゃが芋、人参などの素揚げやグリル野菜をトッピングしても美味しいです。ゆで卵やウインナー、チーズをトッピングするのもおススメです。 "),_c('br'),_vm._v(" 糸唐辛子は彩のためにいれました。なくても大丈夫です。又は他のものでもOKです。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-8 px-10 py-4",staticStyle:{"max-width":"754px","margin":"auto","border":"1px solid #354e57","font-size":"18px"}},[_c('span',[_vm._v(" ガリバタキャベツスープカレーの栄養価 "),_c('br'),_vm._v(" エネルギー 470kcal / たんぱく質 32.5g / 脂質 27.7g / 炭水化物 21.6g / 食塩相当量 3.4g ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3",staticStyle:{"text-align":"center","font-size":"30px","font-weight":"bold"}},[_c('span',[_vm._v("チンして混ぜるだけステーキペッパーライス")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"max-width":"754px","margin":"auto","font-size":"18px"}},[_c('span',[_vm._v(" ■材料1人分 "),_c('br'),_vm._v(" 北海道牛肉ステーキ…1袋（140g） "),_c('br'),_vm._v(" パックご飯…１つ（200g） "),_c('br'),_vm._v(" コーン（缶、パウチ、冷凍など）…大さじ２強（約30g） "),_c('br'),_vm._v(" カット青ねぎ…大さじ2強（約10g） "),_c('br'),_vm._v(" ■作り方 "),_c('br'),_vm._v(" ① パックご飯と牛肉ステーキを表示通り温める。 "),_c('br'),_vm._v(" ② ①を器に入れ、コーンとカット青ねぎも入れて混ぜ合わせ、黒こしょうをかける。 "),_c('br'),_vm._v(" ※味付けは牛肉ステーキのソースのみでOK。お好みで醤油や塩を加えてもOKですが、なるべく塩分量は控えたいところ。黒こしょうをたっぷりかけて召し上がってください。途中で酢やラー油をかけて味変するのもおススメです。 "),_c('br'),_vm._v(" ※さらにアレンジ♪ "),_c('br'),_vm._v(" 混ぜ合わせるだけでも美味しいですが、フライパンで炒めるとチャーハンに！バター、ニンニクと一緒に、玉ねぎやピーマンなどお好みの野菜と炒めるのもおススメです。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-8 px-10 py-4",staticStyle:{"max-width":"754px","margin":"auto","border":"1px solid #354e57","font-size":"18px"}},[_c('span',[_vm._v(" チンして混ぜるだけステーキペッパーライスの栄養価 "),_c('br'),_vm._v(" エネルギー 566kcal / たんぱく質 27.6g / 脂質 10.9g / 炭水化物 86.0g / 食塩相当量 2.3g ")])])
}]

export { render, staticRenderFns }